import { ApiCall } from "../apiBase";

class S3Service {
  /************* get Presigned URL for Datafiel *************/
  async getDatafilePreSignedURL(fileName) {
    let preSignedURL = null;

    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getS3SignedUrl($input: String!) {
            getS3SignedUrl(datafileKey: $input) {
              signedUrl
            }
          }
        `,
        variables: {
          input: fileName
        }
      }
    });

    if (result?.data?.data?.getS3SignedUrl && !result?.data?.errors) {
      preSignedURL = result.data.data.getS3SignedUrl;
    }

    return preSignedURL;
  }

  async getUpdateDatafilePreSignedURL(fileName, datafileId) {
    let preSignedURL = null;

    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getS3UpdateSignedUrl($datafileKey: String!, $datafileId: ID!) {
            getS3UpdateSignedUrl(datafileKey: $datafileKey, datafileId: $datafileId) {
              signedUrl
            }
          }
        `,
        variables: {
          datafileKey: fileName,
          datafileId: datafileId
        }
      }
    });

    if (result?.data?.data?.getS3UpdateSignedUrl && !result?.data?.errors) {
      preSignedURL = result.data.data.getS3UpdateSignedUrl;
    }

    return preSignedURL;
  }

  /************* get Presigned URL for User Avatart *************/
  async getUserAvatarPreSignedURL(fileName) {
    let preSignedURL = null;

    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getUserAvatarUploadURL($input: String!) {
            getUserAvatarUploadURL(imageKey: $input) {
              signedUrl
            }
          }
        `,
        variables: {
          input: fileName
        }
      }
    });

    if (result?.data?.data?.getUserAvatarUploadURL && !result?.data?.errors) {
      preSignedURL = result.data.data.getUserAvatarUploadURL;
    }

    return preSignedURL;
  }

  /************* remove original datafile from S3 *************/
  async removeOriginalDatafileFromS3(datafileKey) {
    let removeDatafileSuccess = null;

    const result = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation removeDatafileFromS3($input: String!) {
            removeDatafileFromS3(datafileKey: $input) {
              status,
              message
            }
          }
        `,
        variables: {
          input: datafileKey
        }
      }
    });
    if (result?.data?.data?.removeDatafileFromS3 && !result?.data?.errors) {
      removeDatafileSuccess = result.data.data.removeDatafileFromS3;
    }
    return removeDatafileSuccess;
  }

  /************* get Presigned URL for Client Avatart *************/
  async getClientLogoAvatarPreSignedURL(fileName, datafileId) {
    let preSignedURL = null;

    let result = await ApiCall({
      method: "post",
      data: {
        query: `
            query getClientLogoAvatarUploadURL($fileName: String!, $datafileId: ID!) {
              getClientLogoAvatarUploadURL(imageKey: $fileName, datafileId: $datafileId) {
                signedUrl
              }
            }
          `,
        variables: {
          fileName: fileName,
          datafileId: datafileId
        }
      }
    });

    if (
      result?.data?.data?.getClientLogoAvatarUploadURL &&
      !result?.data?.errors
    ) {
      preSignedURL = result.data.data.getClientLogoAvatarUploadURL;
    }

    return preSignedURL;
  }
}

export default new S3Service();
